'use client'
import React from 'react';
import styled from 'styled-components';
import Link from "next/link";
import {White, hover, Transition, white, black} from "@/styles/globalStyleVars";
import arrow from '@/public/images/static/arrow-right.svg'
import Image from "next/image";

const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverbackground,
                    target,
                    borderColor,
                    hovercolor,
                    icon,
                    marginSm,
                    onClick,
                    className,
                    Hoverborder
                }) => {


    return (
        <StyledBtn onClick={onClick} className={`${className ? className : null} dc-btn fade-up`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverbackground={hoverbackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hovercolor={hovercolor}
                   onSubmit={onSubmit}
                   icon={icon}
                   marginSm={marginSm}
                   Hoverborder={Hoverborder}
        >
            {src && typeof src === 'string' ? (
                src?.startsWith('http') || src?.startsWith('www') ? (
                    <a href={src} target="_blank" rel="noopener noreferrer">
                        <span>{text}</span>
                    </a>
                ) : (
                    <Link href={src || '/'}>
                        <span>{text}</span>
                    </Link>
                )
            ) : (
                <a target={target || '_self'}>
                    <span>{text}</span>
                </a>
            )}

        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || 'fit-content'};
    height: ${props => props.height || '44'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '14'}px;
      font-weight: ${props => props.fontWeight || 600};
      margin: 0;
      line-height: ${props => props.lineHeight || '20'}px;
      background-color: ${props => props.background || ''};
      position: relative;
      border-radius: ${props => props.borderRadius || '22'}px;
      letter-spacing: ${props => props.letterSpacing || '-0.42'}px;
      overflow: hidden;
      z-index: 0;
      transition: border .3s ease;
      padding: 10px 25px;
      box-sizing: border-box;
      border: ${p => p.border || "0"};
      color: ${props => props.color || `${black}`};
      white-space: nowrap;

      span {
        color: ${props => props.color || `${white}`};
        position: relative;
        z-index: 2;
        transition: color .5s ${Transition};

        img {
          padding-left: 5px;
          filter: none;
          transition: .6s ${Transition};
            // ${p => !p.icon && `display:none`}
        }
      }

      &:before {
        //bottom: 0;
        content: "";
        //display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${p => p.hoverbackground || hover};
        height: 0;
        width: 100%;
        margin: auto;
        transition: all .5s ${Transition};
        border-radius: 22px;
      }

      &:hover {
        span {
          color: ${props => props.hovercolor || ''};
          transition: color .5s ${Transition};
        }

        img {
          filter: invert(92%) sepia(99%) saturate(1%) hue-rotate(235deg) brightness(105%) contrast(100%);
        }

        &:before {
          height: 100%;
        }

        border: ${props => props.Hoverborder || "0"};
      }

      &:focus {
        color: #222222;
      }
    }

    @media (max-width: 600px) {
      ${p => p.marginSm ? `margin:${p.marginSm}` : ''}
    }
  }




`;


export default Button;
