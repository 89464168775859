'use client'
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "@/components/Img";
import Button from "@/components/Button";
import {black} from "@/styles/globalStyleVars";
import ButtonNormal from "@/components/ButtonNormal";

const ErrorPage = () => {

    return (
        <StyledErrorPage className='StyledErrorPage'>
            <Container>
                <Row>
                    <Col md={4}>
                        <h2>404</h2>
                        <h3>Page not <span>found</span> </h3>
                        <ButtonNormal background={'#959185'} hoverbackground={'#fff'} color={'#fff'} hovercolor={'#000'} margin={'50px 0 0 0'} marginSm={"30px 0 0 0"} src={"/"} text="Return Home"/>
                    </Col>
                    <Col md={{offset:4,span:4}}>
                        <div className={'image-wrapper'}>
                            <Img src={'/images/dynamic/error.jpg'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledErrorPage>
    );
};

const StyledErrorPage = styled.div`
  background: #F1F0EE;
  position: relative;
  padding-top: 50px;
  height: ${`100svh`};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    align-items: center;
  }

  h2 {
    color: ${black};
    font-size: 60px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    font-family: PP Migra;
  }

  h3 {
    color: ${black};
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -1.44px;

    span {
      font-family: PP Migra;
      font-style: italic;
    }
  }

  .image-wrapper {
    position: relative;
    padding-top: calc(400 / 350 * 100%);
  }


  @media (max-width: 991px) {
    .col-sm-8 {
      min-width: 100%;
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    .offset-md-4 {
      display: none;
    }
  }
`;
export default ErrorPage;
